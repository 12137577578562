<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Verification Portal</h1>
      <div class="flex">
        <div class="">
          <button
            class="bg-gray-800 py-3 px-3 rounded-md text-white"
            @click="
              $router.push({
                name: 'DocumentVerification',
              })
            "
          >
            &laquo; Back
          </button>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          class="text-right px-3 cursor-pointer py-2 text-xs uppercase text-white bg-blue-800"
          @click="
            $router.push({
              name: 'ViewVerification',
              params: {
                id: documents.merchant_id,
              },
            })
          "
        >
          <i class="bi bi-arrow-left-circle"></i> View all verification
        </button>
        <button
          :class="
            documents.verificationStatus === 'VERIFIED'
              ? 'bg-green-500 text-white'
              : documents.verificationStatus === 'FAILED'
              ? 'bg-red-100 text-black'
              : 'bg-gray-500 text-black'
          "
          class="py-3 px-6 rounded-md"
          style="float: right"
          disabled
        >
          {{ documents.verificationStatus }}
        </button>
      </div>
      <div class="flex my-10">
        <div class="w-1/2">
          <div
            class="flex justify-center flex-col p-5 border-2 border-gray-50 shadow-lg rounded-md"
          >
            <h4 class="text-xl font-bold">Merchant Information</h4>
            <div class="grid grid-cols-2 gap-4 mt-5">
              <div class="py-1">
                <h5 class="text-xs font-bold">Business Name</h5>
                <p class="flex text-sm mb-3">{{ documents.business_name }}</p>
                <h5 class="text-xs font-bold">Merchant ID</h5>
                <p class="text-left mb-3">
                  {{ documents.merchant_id }}
                </p>
                <h5 class="text-xs font-bold">Date of Signup:</h5>
                <p class="flex text-sm mb-3">
                  {{
                    moment(documents.date_of_signup).format(
                      "DD MMM YYYY - h:mm:ss a"
                    )
                  }}
                </p>
                <h5 class="text-xs font-bold">Last KYC Update</h5>
                <p class="flex mb-3">
                  {{
                    moment(documents.last_kyc_update).format(
                      "DD MMM YYYY - h:mm:ss a"
                    )
                  }}
                </p>
                <h5 class="text-xs font-bold">Email Verification:</h5>
                <p class="flex mb-3">{{ documents.email_verification }}</p>

                <p class="text-xs font-bold">Phone Verification</p>
                <p class="flex mb-3">{{ documents.phone_verification }}</p>
              </div>
              <div class="py-1">
                <p class="text-xs font-bold">Settlement Preference</p>
                <p class="flex mb-3">{{ documents.settlement_type }}</p>
                <p class="text-xs font-bold">Selfie Verification:</p>
                <p class="flex mb-3">TRUE</p>
                <p class="text-xs font-bold">Document Verification</p>
                <p class="flex mb-3">
                  {{ documents.documentVerificationStatus }}
                </p>
                <p class="text-xs font-bold">ID Type</p>
                <p class="flex mb-3">
                  {{ documents.document_type }}
                </p>
                <button
                  @click="approveBlacklistIDModal = true"
                  class="block uppercase shadow bg-red-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-3 rounded"
                >
                  Blacklist ID
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-1 my-10 w-full border-2 border-gray-50 shadow-lg">
        <div class="grid grid-cols-2 gap-3 w-full">
          <div class="justify-center flex-col items-center py-2">
            <div class="grid grid-cols-2 gap-4 p-10">
              <div>
                <h4 class="font-bold text-xl">Selfie</h4>
                <img
                  :src="documents.photo"
                  alt="No Selfie photo to show"
                  style="width: 200px; height: 200px"
                />
                <div class="mt-5">
                  <p>
                    <strong class="text-sm">Match Score: </strong>
                    {{ documents.match_score }}
                  </p>
                  <p>
                    <strong class="text-sm">Match Threshold: </strong>
                    {{ documents.match_score }}
                  </p>
                  <p>
                    <strong class="text-sm">Max Score: </strong>
                    {{ documents.max_score }}
                  </p>
                </div>
              </div>
              <div>
                <h4 class="font-bold text-xl">NIN Upload</h4>
                <img
                  :src="documents.documentImageUrl"
                  alt="No document photo to show"
                  style="width: 200px; height: 200px"
                />
              </div>
            </div>
          </div>
          <div class="py-2 justify-center flex-col bg-blue-50">
            <h4 class="text-xl pt-10 px-10">Verify Me</h4>
            <div class="grid grid-cols-2 gap-4 px-10">
              <div class="justify-center flex-col items-center p-2">
                <p class="flex text-sm font-bold">First Name</p>
                <p class="text-sm mb-5">{{ documents.firstname }}</p>
                <p class="flex text-sm font-bold">Middle Name</p>
                <p class="text-sm mb-5">
                  {{ documents.middlename }}
                </p>
                <p class="text-sm font-bold">Last Name:</p>
                <p class="text-sm mb-5">{{ documents.lastname }}</p>
                <p class="font-bold text-sm">Gender</p>
                <p class="text-sm mb-5">
                  {{ documents.gender }}
                </p>
              </div>

              <div class="justify-center flex-col items-center py-2">
                <p class="text-sm font-bold">ID Number</p>
                <p class="text-sm mb-5">
                  {{ documents.verificationReference }}
                </p>
                <p class="text-sm font-bold">Phone</p>
                <p class="text-sm mb-5">
                  {{ documents.phone }}
                </p>
                <p class="text-sm font-bold">DOB</p>
                <p class="text-sm mb-5">
                  {{ documents.birthdate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between mt-10 mb-10 float-right">
        <button
          class="border-red-500 py-3 px-6 rounded-md mx-2 border-2 text-red"
          @click="rejectModal = true"
          v-if="documents.verificationStatus === 'PENDING'"
        >
          Reject
        </button>
        <button
          @click="approveKYC"
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
          v-if="documents.verificationStatus === 'PENDING'"
        >
          Approve
        </button>
      </div>
      <div
        v-if="approveBlacklistIDModal"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div class="w-2/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4">
          <span class="cursor-pointer" @click="approveBlacklistIDModal = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-lg float-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fill-rule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>
          </span>
          <p class="text-blue-900 font-semibold text-xl">
            Add reason for blacklisting
          </p>
          <div class="grid grid-cols-1 gap-5">
            <div class="flex flex-col pt-4">
              <textarea
                v-model="blacklistingID_reason"
                placeholder="Enter Reason for Rejection"
                class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div class="justify-end flex">
            <button
              class="inline-block uppercase shadow mt-10 mx-3 leading-6 hover:bg-red-300 focus:shadow-outline focus:outline-none text-blue text-xs py-2 px-8 rounded"
              @click="approveBlacklistIDModal = false"
            >
              Cancel
            </button>
            <button
              @click="handleBlacklistingAction"
              class="inline-block uppercase shadow bg-blue-600 mt-10 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Done
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="rejectModal"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div class="w-2/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4">
          <span class="cursor-pointer" @click="rejectModal = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-lg float-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fill-rule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>
          </span>
          <p class="text-blue-900 font-semibold">Rejection List</p>
          <p class="text-xs">
            Choose rejection reason from list or enter rejection reason
          </p>
          <div class="flex flex-wrap xl:-mx-4">
            <div class="w-1/3 xl:my-4 xl:px-4">
              <label
                for="ID does not match Selfie"
                class="w-full p-5 border-2 d-inline-block border-gray-100 bg-white rounded-lg cursor-pointer hover:bg-blue-900 hover:text-white"
                style="display: inline-block"
              >
                <p class="text-xs">
                  <input
                    type="checkbox"
                    v-model="reject_reason_type"
                    id="ID does not match Selfie"
                  />
                  ID does not match Selfie
                </p>
              </label>
            </div>
          </div>
          <form @submit.prevent="disapproveKYC">
            <div class="grid grid-cols-1 gap-5">
              <div class="flex flex-col pt-4">
                <textarea
                  v-model="reject_reason"
                  placeholder="Enter Reason for Rejection"
                  class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                ></textarea>
              </div>
            </div>
            <button
              class="inline-block uppercase shadow mt-10 mx-3 leading-6 hover:bg-red-300 focus:shadow-outline focus:outline-none text-blue text-xs py-2 px-8 rounded"
              @click="rejectModal = false"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="inline-block uppercase shadow bg-blue-600 mt-10 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      verified: false,
      rejectModal: false,
      reject_reason: "",
      reject_reason_type: "ID does not match Selfie",
      approveBlacklistIDModal: false,
      blacklistingID_reason: "",
      payload: {
        businessId: "",
        businessName: "",
        Status: "",
        comment: "",
      },
      documents: {
        business_name: "",
        merchant_id: "",
        date_of_signup: "",
        last_kyc_update: "",
        email_verification: "",
        phone_verification: "",
        selfie_response: [],
        selfiePhoto: "",
        match_score: "",
        match_threshold: "",
        max_score: "",
        idType: "",
        selfieVerificationStatus: "",
        documentVerificationStatus: "",
        firstname: "",
        middlename: "",
        lastname: "",
        gender: "",
        bvn: "",
        phone: "",
        birthdate: "",
        nationality: "",
        status: null,
        documentImageUrl: "",
        settlement_type: "",
        verificationStatus: "",
        document_type: "",
      },
    };
  },
  computed: {
    // formatDocuments() {
    //   return this.documents;
    // },
  },

  async created() {
    this.loadVerificationDocument();
  },
  methods: {
    async loadVerificationDocument() {
      this.isLoading = true;
      let res = await this.$store.dispatch(
        "FETCH_SINGLE_VERIFICATION_DOCUMENT",
        {
          id: this.$route.params.id,
        }
      );
      // console.log(res.data);
      if (res.status == true) {
        this.isLoading = false;
        this.documents.business_name =
          res.data.BusinessName === "" ? "N/A" : res.data.businessInfo.name;
        this.documents.merchant_id =
          res.data.BusinessId === "" ? "N/A" : res.data.businessInfo._id;
        this.documents.date_of_signup = res.data.businessInfo.created_at;
        this.documents.last_kyc_update = res.data.businessInfo.update_at;
        this.documents.email_verification = res.data.businessInfo.email;
        this.documents.phone_verification = res.data.businessInfo.phone;
        this.documents.documentImageUrl =
          res.data.verificationData.documentImageUrl;
        this.documents.photo = res.data.verificationData.selfieUrl;
        this.documents.settlement_type = res.data.businessInfo.settlement_type;
        // this.documents.selfieVerificationStatus =
        //   res.data.SelfieResponse.status === ""
        //     ? "N/A"
        //     : res.data.SelfieResponse.status;
        this.documents.verificationReference = res.data.verificationReference;
        this.documents.documentVerificationStatus =
          res.data.businessInfo.kyc_status === ""
            ? "N/A"
            : res.data.businessInfo.kyc_status;

        this.documents.verificationStatus =
          res.data.verificationData.verificationStatus === ""
            ? "N/A"
            : res.data.verificationData.verificationStatus;

        this.documents.match_score =
          res.data.verificationData.photoMatching.matchScore;
        this.documents.match_threshold =
          res.data.verificationData.photoMatching.matchingThreshold;

        this.documents.max_score =
          res.data.verificationData.photoMatching.maxScore;
        this.documents.firstname = res.data.verificationData.firstName;
        this.documents.middlename = res.data.verificationData.middleName;
        this.documents.lastname = res.data.verificationData.lastName;
        this.documents.gender = res.data.verificationData.gender;
        this.documents.bvn = res.data.verificationData.bvn;
        this.documents.phone = res.data.verificationData.phone;
        this.documents.birthdate = res.data.verificationData.dob;
        this.documents.status = res.data.verificationData.verificationStatus;
        this.documents.document_type =
          res.data.verificationData.document_type === ""
            ? "N/A"
            : res.data.verificationData.document_type;
        this.verified =
          res.data.verificationData.verificationStatus === "APPROVED"
            ? true
            : false;
      } else {
        this.isLoading = false;
        alert("Sorry, could not find any detail for this record");
      }
    },
    dismissModal(e) {
      const target = e.target;
      if (target.classList.contains("modal")) {
        this.rejectModal = false;
        console.log("Close modal");
      }
    },
    async approveKYC() {
      const response = confirm("Are you sure you want to do this?");
      if (response) {
        this.isLoading = true;
        let payload = {
          comment: "",
          status: "APPROVED",
          id: this.$route.params.id,
        };
        let res = await this.$store.dispatch("APPROVE_KYC", payload);

        if (res.status) {
          alert("Success!");
          this.$router.go();
          this.verified = true;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          alert(res.error);
        }
      }
      this.isLoading = false;
    },
    async disapproveKYC() {
      const response = confirm("Are you sure you want to do this?");
      if (response) {
        this.isLoading = true;
        let reason =
          this.reject_reason !== ""
            ? this.reject_reason
            : this.reject_reason_type;
        let payload = {
          comment: reason,
          status: "DECLINED",
          id: this.$route.params.id,
        };
        let res = await this.$store.dispatch("DISAPPROVE_KYC", payload);

        if (res.status) {
          this.isLoading = false;
          alert("Success!");
          this.$router.go();
        } else {
          this.isLoading = false;
          alert(res.error);
        }
      }
    },
    async handleBlacklistingAction() {
      const response = confirm("Are you sure you want to do this?");
      if (response) {
        this.isLoading = true;
        let payload = {
          note: this.blacklistingID_reason,
          idNumber: this.documents.verificationReference,
          idType: "bvn",
          id: this.documents.merchant_id,
        };
        let res = await this.$store.dispatch("BLACKLIST_ID", payload);
        this.isLoading = false;

        if (res.status) {
          alert("Success!");
          this.loadVerificationDocument();
          this.approveBlacklistIDModal = false;
        } else {
          this.isLoading = false;
          alert(res.error);
        }
      }
    },
  },
};
</script>

<style></style>
